import { Heading, P, Section, Wrapper } from 'components/base';
import Layout2 from 'components/Layout2';
import Markdown from 'components/Markdown';
import Metadata from 'components/Metadata';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { back, dateC } from './ArticleTpl.module.scss';

const ArticleTpl = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { slug, title, description, date },
    },
  } = data;
  const renderhtmlfromProps = data => ({ __html: data });
  const location = { pathname: slug };
  
  return (
    <Layout2 location={location}>
      <Metadata title={title} description={description} />
      <Section>
        <Wrapper size="M">
          <P className={back} size="S">
            <Link to="/projects/atomatic-swap#article">← BACK</Link>
          </P>
          <Heading Tag="h1" size="L" marginBottom="S" bold>
            {title}
          </Heading>
          <P size="S" className={dateC} marginBottom="Xxs">
            更新日期：{date}
          </P>
          <Markdown>
            <div dangerouslySetInnerHTML={renderhtmlfromProps(html)} />
          </Markdown>
          <P className={back} size="S">
            <Link to="/projects/atomatic-swap#article">← BACK</Link>
          </P>
        </Wrapper>
      </Section>
    </Layout2>
  );
};

ArticleTpl.propTypes = {
  data: PropTypes.object,
};

export default ArticleTpl;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        slug
        title
        description
        date
      }
    }
  }
`;
