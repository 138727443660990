import PropTypes from 'prop-types';
import React from 'react';
import { root } from './Markdown.module.scss';

const Markdown = props => <div className={root}>{props.children}</div>;

Markdown.propTypes = {
  children: PropTypes.node,
};

export default Markdown;
