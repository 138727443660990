import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './Wrapper.module.scss';

const sizeOptions = ['Xl', 'L', 'ML', 'M', 'MS', 'SL', 'S'];

const Wrapper = ({ Tag, size, children, className }) => (
  <Tag className={cn(styles[`size${size}`], className)}>{children}</Tag>
);
Wrapper.propTypes = {
  Tag: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(sizeOptions).isRequired,
  className: PropTypes.string,
};
Wrapper.defaultProps = {
  size: 'L',
  Tag: 'div',
};

export default Wrapper;
